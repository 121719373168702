import { Atom, Cmp, Mod } from ":mods";
import { createStore } from "solid-js/store";
import { For, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { AMaterialTaskContent, MaterialTaskDesignView } from "src/modules/courses/models";
import { checkOptions } from "../methods";
//? response and request payloads
type RecievedPayload = AMaterialTaskContent<
  [
    {
      image: any;
      title: string;
      options: { id: number; value: string }[];
      option_answer?: { id: number; value: string };
    },
    { [k: string]: string; title$: string },
    { [k: string]: string; title$: string },
    {
      title$: string;
      rating: {
        max: 5;
        answer: null;
        title$: "Physical Experience";
      }[][];
    }
  ],
  true
>;
type RequestPayload = AMaterialTaskContent<
  [
    {
      image: any;
      title: string;
      options: number;
      option_answer?: { id: number; value: string };
    },
    { [k: string]: string; title$: string },
    { [k: string]: string; title$: string },
    {
      title$: string;
      rating: {
        max: 5;
        answer: null;
        title$: "Physical Experience";
      }[][];
      update_ratings?: {
        outerIndex: number;
        innerIndex: number;
        ans: number;
      };
    }
  ],
  true
>;
type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem0 = Partial<RequestPayloadSectionContent[0]>;
type RequestPayloadSectionContentItem1 = Partial<RequestPayloadSectionContent[1]>;
type RequestPayloadSectionContentItem2 = Partial<RequestPayloadSectionContent[2]>;
type RequestPayloadSectionContentItem3 = Partial<RequestPayloadSectionContent[3]>;
// -------------
//? persona
// -------------
const NUMBER_VALUES_KEYS = ["age", "income"];
export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  // setup vars before content
  let images = [];

  // track parent content
  const content = createMemo(() => {
    // on content updated or discarded
    images = [];
    return props.elements.data.content as RecievedPayload;
  });

  function onDataChange(idx: number, props: Partial<RequestPayloadSectionContent[number]>) {
    const items = content()[0].content as unknown as RequestPayloadSectionContent;
    // @ts-ignore
    items[idx] = { ...items[idx], ...props };
    //? this is where you process data before passing it to update
    for (const item of items) {
      // const new_item = { ...itxem, ...props } as RequestPayloadSectionContentItem;
      if ("image" in item) {
        if (typeof item.image !== "boolean") {
          item.image = false;
        }
        // @ts-ignore
        checkOptions(item);
      } else if ("update_ratings" in item) {
        // process update_ratings
        const update_ratings = item.update_ratings as Exclude<typeof item.update_ratings, string>;
        const outer_arr = item.rating[update_ratings.outerIndex];
        const obj = outer_arr[update_ratings.innerIndex];
        // @ts-ignore
        obj.answer = update_ratings.ans;
        delete item.update_ratings;
      } else if ("age" in item) {
        const is_not_number = Number.isNaN(parseFloat(item.age));
        if (is_not_number) {
          delete item.age;
        }
      } else if ("income" in item) {
        const is_not_number = Number.isNaN(parseFloat(item.income));
        if (is_not_number) {
          delete item.income;
        }
      }
    }
    // pass data to update
    console.log("d :: ", items);
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    images.map((imagefile, i) => formData.append("images", imagefile, `tp07 image ${i + 1}`));
    formData.append("data", JSON.stringify(data));
    props.events?.onDataUpdate?.(formData, data);
  }
  return (
    <form class="w-full">
      <For each={content()}>
        {(section, index) => {
          // function retrun(arg0: number | boolean | Node | JSX.ArrayElement | (string & {})) {
          //   throw new Error("Function not implemented.");
          // }

          return (
            <section class="w-full">
              <h1 class="text-10 font-700 mt-40px mb-20px py-3">
                {/* section.details?.title || section.details[0]?.title || */}
                {"Persona"}
              </h1>
              <section class="flex flex-wrap gap-2rem pr-0.8rem">
                <For each={section.content}>
                  {(_content, idx) => {
                    // @ts-ignore
                    const { title$, ...content } = _content;
                    if ("image" in content) {
                      return (
                        <div class="flex flex-row w-full max-w-full h-25rem gap-20px h-fit">
                          {/* title and option */}
                          <div class="flex flex-col w-60% h-full gap-20px ">
                            <Atom.Tasks.Title
                              class={"h-25 w-full text-63px leading-67px"}
                              elements={{
                                title: content.title,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onChange: (e) => onDataChange(idx(), { title: e.target.value }),
                              }}
                            />
                            <Atom.Tasks.Dropdown
                              class="w-full h-55px "
                              placeholder="Select Personality Type from 5 Segments"
                              elements={{
                                // @ts-ignore
                                options: content.options,
                                // @ts-ignore
                                opt_ans: content?.option_answer?.id,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                // @ts-ignore
                                onValueChange: (e) => onDataChange(idx(), { option_answer: e }),
                              }}
                            />
                          </div>
                          {/* image must be almost squared, little bit more height than width */}
                          <Atom.Tasks.Image
                            class="w-full h-full "
                            options={{
                              editable: props.options.editable,
                            }}
                            elements={{
                              images: images,
                              image: content.image,
                            }}
                            events={{
                              onImageChange: (e) => onDataChange(idx(), { image: e }),
                            }}
                          />
                        </div>
                      );
                    }

                    const Title = () => (
                      <div>
                        <Atom.Tasks.Title
                          class="text-25px font-700"
                          elements={{
                            title: title$,
                          }}
                          options={{
                            editable: props.options.editable && !title$,
                          }}
                        />
                      </div>
                    );

                    if ("rating" in content) {
                      return (
                        <div class="w-full flex flex-col gap-20px">
                          <Title />
                          <For each={content.rating as Exclude<typeof content.rating, string>}>
                            {(ratingList, outerIndex) => {
                              return (
                                <div class="flex gap-20px justify-evenly ">
                                  <For each={ratingList}>
                                    {(rate, innerIndex) => {
                                      const inputName = rate.title$?.toLowerCase().split(" ").join("-");
                                      const maxRating = Array(rate.max + 1).fill(1);
                                      return (
                                        <div class="flex flex-col gap-20px w-full">
                                          <h4 class="text-16px font-700 leading-24px pl-10px">{rate.title$}</h4>
                                          <div class="flex w-full items-center justify-evenly gap-20px">
                                            <For each={maxRating}>
                                              {(n, i) => {
                                                return (
                                                  <div id={rate.title$} class="flex items-center gap-15px text-16px">
                                                    <p>{i()}</p>
                                                    <input
                                                      id={innerIndex.toString() + outerIndex.toString()}
                                                      onChange={(e) =>
                                                        onDataChange(idx(), {
                                                          update_ratings: {
                                                            outerIndex: outerIndex(),
                                                            innerIndex: innerIndex(),
                                                            ans: i(),
                                                          },
                                                        })
                                                      }
                                                      name={inputName}
                                                      type="radio"
                                                      class="radioFullBlack"
                                                      checked={rate.answer == i()}
                                                    />
                                                  </div>
                                                );
                                              }}
                                            </For>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </For>
                                </div>
                              );
                            }}
                          </For>
                        </div>
                      );
                    }

                    // interesets
                    if (!("age" in content)) {
                      return (
                        <div class="flex flex-col w-full gap-0.5rem">
                          <Title />
                          <For each={Object.keys(content)}>
                            {(key, key_index) => {
                              return (
                                <Atom.Tasks.SingleInput
                                  elements={{
                                    input: content[key],
                                  }}
                                  options={{
                                    editable: props.options.editable,
                                  }}
                                  events={{
                                    onChange: (e) => onDataChange(idx(), { [key]: e.target.value }),
                                  }}
                                />
                              );
                            }}
                          </For>
                        </div>
                      );
                    }
                    const { age, gender, material_status, description, ...rest_of_content } = content;
                    const first_row = { age, gender, material_status };
                    return (
                      <main class="grid grid-cols-[1fr_1fr] grid-rows-[12%_80%] gap-x-1rem w-full h-full ">
                        <Title />
                        <section class="grid-row-[2] flex flex-col space-y-2">
                          <div class="flex flex-row w-full space-x-2">
                            <For each={Object.keys(first_row)}>
                              {(key, key_index) => {
                                const is_number = NUMBER_VALUES_KEYS.includes(key.toLowerCase());
                                return (
                                  <Atom.Tasks.SingleInput
                                    type={is_number ? "number" : "text"}
                                    elements={{
                                      input: first_row[key],
                                    }}
                                    options={{
                                      editable: props.options.editable,
                                    }}
                                    placeholder={key}
                                    events={{
                                      onChange: (e) =>
                                        onDataChange(idx(), {
                                          [key]: is_number ? parseFloat(e.target.value) : e.target.value,
                                        }),
                                    }}
                                  />
                                );
                              }}
                            </For>
                          </div>
                          <For each={Object.keys(rest_of_content)}>
                            {(key, key_index) => {
                              const is_number = NUMBER_VALUES_KEYS.includes(key.toLowerCase());
                              return (
                                <Atom.Tasks.SingleInput
                                  type={is_number ? "number" : "text"}
                                  elements={{
                                    input: rest_of_content[key],
                                  }}
                                  options={{
                                    editable: props.options.editable,
                                  }}
                                  placeholder={key}
                                  events={{
                                    onChange: (e) =>
                                      onDataChange(idx(), {
                                        [key]: is_number ? parseFloat(e.target.value) : e.target.value,
                                      }),
                                  }}
                                />
                              );
                            }}
                          </For>
                        </section>
                        <div class="grid-row-[2] grid-col-[2]">
                          <Atom.Tasks.Description
                            class="w-full h-full"
                            elements={{
                              description: description,
                            }}
                            options={{
                              editable: props.options.editable,
                            }}
                            events={{
                              onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                            }}
                          />
                        </div>
                      </main>
                    );
                  }}
                </For>
              </section>
            </section>
          );
        }}
      </For>
    </form>
  );
}
